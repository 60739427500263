<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  components: {},
  data() {
    return {
      rule: [],
      formFunctionCode: 'kms_customer_account_form',
    };
  },
  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'remarks') {
        item.props = {
          ...item.props,
          type: 'textarea',
        };
      }
      if (item.field === 'directSystemId') {
        item.restful = '/mdm/mdmDirectSystemController/directSystemSelect';
        item.optionsKey = { label: 'directSystemName', value: 'directSystemId' };
        item.restfulParams = {
          enableStatus: '009',
        };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'directSystemName',
        };
      }
      if (item.field === 'loginPass') {
        item.props = {
          ...item.props,
          placeholder: '登录密码',
        };
      }
      if (item.field === 'confirmPass') {
        item.props = {
          ...item.props,
          placeholder: '确认密码',
        };
      }
      if (item.field === 'loginAcc') {
        item.validate = [
          ...item.validate,
          {
            trigger: 'blur',
            pattern: '^[a-zA-Z0-9]*$',
            message: '仅能输入英文和数字的组合',
          },
        ];
      }
      return item;
    },
    // 表单渲染完成后回调
    formComplete() {
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        this.setValue(
          {
            ...this.formConfig.row,
            confirmPass: this.formConfig.row.loginPass,
          },

        );
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData.loginPass !== formData.confirmPass) {
        this.$message.error('登录密码和确认密码不一致，请重新输入');
        return false;
      }
      if (formData) {
        let url = '/mdm/mdmCustomerAccountController/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/mdm/mdmCustomerAccountController/update';
          params.id = this.formConfig.row.id;
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
